<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else-if="!integration.connected">
    <div class="btn btn-primary">Подключить</div>
    <a href="#">Инструкция</a>
  </div>
  <div v-else>
    <div class="modulbank-accounts">
      <div class="modulbank-accounts-top">
        <div class="modulbank-accounts-title">
          <div>Настройка счетов</div>
          <span class="modulbank-account-invoice">Найдено {{ accounts.length | pluralize(['счет', 'счета', 'счетов']) }}</span>
        </div>
        <div class="ml-auto">
          <a class="btn btn-primary" @click="selectAllAccounts">Подключить все</a>
        </div>
      </div>
      <div class="modulbank-account" v-for="account in accounts" :key="account.id">
        <div class="modulbank-account-title">
          <router-link v-if="account.org" class="modulbank-account-company"
            :to="{ name: 'fin/settings/orgs/detail', params: { id: account.org } }">{{ account.companyName }}</router-link>
          <div v-else class="modulbank-account-company">{{ account.companyName }}</div>
          <router-link v-if="account.org_invoice" class="modulbank-account-invoice"
            :to="{ name: 'fin/settings/org-invoices/detail', params: { id: account.org_invoice } }">{{ account.accountName }} {{ account.number.substr(-4) }}</router-link>
          <span v-else class="modulbank-account-invoice">{{ account.accountName }} {{ account.number.substr(-4) }}</span>
        </div>
        <div class="ml-auto">
          <div class="btn" @click="toggleAccount(account.id)"
            :class="{ 'btn-primary': !selectedAccounts.includes(account.id), 'btn-danger': selectedAccounts.includes(account.id) }">
            {{ selectedAccounts.includes(account.id) ? 'Отключить' : 'Подключить' }}
          </div>
        </div>
      </div>
    </div>

    <div class="modulbank-entities">
      <div class="modulbank-entities-title">Синхронизация транзакций</div>
      <div class="modulbank-entity">
        <div class="modulbank-entity-title">Транзакции</div>
        <div class="modulbank-entity-right ml-auto">
          <div v-if="message" class="modulbank-entity-message">{{ message }}</div>
          <button class="btn btn-primary" :disabled="syncing" @click="sync">
            <span v-if="syncing">Синхронизация</span>
            <span v-else>Синхронизировать</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import NotifyMixin from '@/mixins/NotifyMixin.js'

export default {
  name: 'ModulbankIntegration',
  mixins: [NotifyMixin],
  data () {
    return {
      isLoading: true,
      integration: [],
      accounts: [],
      selectedAccounts: null,
      message: '',
      syncing: false
    }
  },
  watch: {
    selectedAccounts (val, oldVal) {
      if (oldVal === null) {
        return
      }
      this.$api.post('modulbank/account', val)
    }
  },
  async created () {
    this.integration = await this.$api.query('integration/modulbank')

    this.accounts = await this.$api.query('modulbank/info/accounts')

    this.selectedAccounts = await this.$api.query('modulbank/account')
    this.selectAllAccounts = this.selectedAccounts.filter(el => el.active).map(el => el.id)

    this.isLoading = false
  },
  methods: {
    toggleAccount (id) {
      let index
      if ((index = this.selectedAccounts.indexOf(id)) !== -1) {
        Vue.delete(this.selectedAccounts, index)
      } else {
        this.selectedAccounts.push(id)
      }
    },
    selectAllAccounts () {
      this.selectedAccounts = this.accounts.map(el => el.id)
    },
    async sync () {
      if (this.syncing) {
        return
      }

      this.syncing = true
      for (let i = 0; i < this.accounts.length; i++) {
        let start = 0
        let count = 0
        do {
          try {
            const result = await this.$api.query('modulbank/operation/sync', { account: this.accounts[i].id, start })
            start = result.next ?? 0
            count += result.count ?? 0

            this.message = 'Счет ' + (i + 1) + ' из ' + this.accounts.length + ': ' + count + ' транзакций'
          } catch (err) {
            this.notifyError('Синхронизация Модульбанк', 'Не удалось синхронизировать транзакции')
            this.message = err
            this.syncing = false
            return
          }
        } while (start)
      }

      this.notifySuccess('Синхронизация Модульбанк', 'Транзакции успешно синхронизированы!')
      this.syncing = false
    }
  }
}
</script>

<style scoped>
.modulbank-accounts {
  margin-bottom: 30px;
  background-color: var(--grey-color);
  padding: 45px 50px 25px;
}
.modulbank-accounts-top {
  display: flex;
}
.modulbank-accounts-title {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--title-color);
}
.modulbank-accounts-title span {
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-color);
  margin-top: 10px;
}
.modulbank-account {
  display: flex;
  padding: 25px 0;
  border-top: 1px solid #ffffff
}
.modulbank-account-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--title-color);
}
.modulbank-account-title .modulbank-account-invoice {
  display: block;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
  margin-top: 5px;
}
.modulbank-account-title a.modulbank-account-invoice {
  color: var(--title-color);
}
.modulbank-entities {
  padding: 45px 50px;
  background-color: var(--grey-color);
}
.modulbank-entities-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--title-color);
  margin-bottom: 50px;
}
.modulbank-entity {
  display: flex;
  align-items: center;
  padding: 25px 0;
  border-top: 1px solid #ffffff;
}
.modulbank-entity-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--title-color);
}
.modulbank-entity-right {
  display: flex;
  align-items: center;
}
.modulbank-entity-message {
  font-size: 14px;
  line-height: 19px;
  color: #555555;
  margin-right: 50px;
}
</style>

<template>
  <div class="bitrix24-entities">
    <div class="bitrix24-entities-title">
      <h3>Синхронизация сущностей</h3>
      <button class="btn btn-primary" :disabled="disabled" @click="syncAll">Синхронизировать все</button>
    </div>
    <div class="bitrix24-entity" v-for="(item, index) in entities" :key="item.code">
      <div class="bitrix24-entity-inner">
        <div v-if="item.percent > 0" class="bitrix24-entity-circle"><CirclePercent :percent="item.percent" /></div>
        <div class="bitrix24-entity-title">
          {{ item.title }}
          <span v-if="item.percent > 0">{{ item.percent }}%</span>
        </div>
        <div v-if="item.message" class="bitrix24-entity-message">{{ item.message }}</div>
        <button class="btn btn-primary" :disabled="disabled" @click="sync(index)">
          <span v-if="item.syncing">Синхронизация</span>
          <span v-else>Синхронизировать</span>
        </button>
      </div>
      <div v-if="item.options && item.bitrix24Options && item.bitrix24Options.length > 0" class="bitrix24-entity_settings mt-3">
        <table class="table border">
          <tr class="text-center">
            <th colspan="3" class="table-secondary">Связанные поля</th>
          </tr>
          <tr class="text-center">
            <th>Поле приложения</th>
            <th>Поле Битрикс24</th>
            <th></th>
          </tr>
          <template v-if="item.value && item.value.length > 0">
            <tr
              v-for="option in item.value"
              :key="option.name"
              class="text-center">
              <td class="align-middle">
                <span>{{ item.options.find(el => el.value === option.name).text }}</span>
              </td>
              <td class="align-middle">
                <b-form-select
                  v-model="option.value"
                  style="max-width: 250px"
                  :options="item.bitrix24Options"
                  @change="saveOption(item.code, option.name, option.value)"
                />
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-secondary"
                  @click="clearOption(item.code, option.name, '')"
                >
                  Очистить
                </button>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import CirclePercent from '@/components/ui/CirclePercent'
import NotifyMixin from '@/mixins/NotifyMixin.js'

export default {
  name: 'Bitrix24Integration',
  components: {
    CirclePercent
  },
  mixins: [NotifyMixin],
  data () {
    return {
      entities: [
        {
          code: 'company',
          title: 'Компании'
        },
        {
          code: 'contact',
          title: 'Контакты'
        },
        {
          code: 'invoice',
          title: 'Счета'
        },
        {
          code: 'product-section',
          title: 'Разделы товаров'
        },
        {
          code: 'product',
          title: 'Товары'
        },
        {
          code: 'lead',
          title: 'Лиды'
        },
        {
          code: 'pipeline',
          title: 'Направления сделок'
        },
        {
          code: 'stage',
          title: 'Стадии сделок'
        },
        {
          code: 'deal',
          title: 'Сделки',
          bitrix24Options: [],
          value: [],
          options: [
            {
              value: 'send_bill_date',
              text: 'Дата отправки счета'
            },
            {
              value: 'paid_bill_date',
              text: 'Дата оплаты счета'
            }
          ]
        },
        {
          code: 'requisite',
          title: 'Реквизиты'
        },
        {
          code: 'department',
          title: 'Подразделения'
        },
        {
          code: 'user',
          title: 'Пользователи'
        },
        {
          code: 'call',
          title: 'Звонки'
        },
        {
          code: 'activity',
          title: 'Дела'
        }
      ],
      disabled: false
    }
  },
  async created () {
    await this.fetchSettings()
    await this.fetchDealFields()
  },
  methods: {
    async sync (index) {
      this.$store.dispatch('integration/bitrix24/sync', {
        entity: this.entities[index].code,
        start: () => {
          this.disabled = true
          Vue.set(this.entities[index], 'syncing', true)
        },
        finish: () => {
          this.notifySuccess('Синхронизация Битрикс24', 'Синхронизация сущности ' + this.entities[index].title + ' успешно завершена!')
          Vue.set(this.entities[index], 'syncing', false)
          this.disabled = false
        },
        step: ({ count, total }) => {
          Vue.set(this.entities[index], 'percent', Math.floor(count / total * 100))
          Vue.set(this.entities[index], 'message', count + ' из ' + total)
        },
        error: (err) => {
          Vue.set(this.entities[index], 'syncing', false)
          Vue.set(this.entities[index], 'message', err)
          this.notifyError('Синхронизация Битрикс24', 'Не удалось синхронизировать сущность ' + this.entities[index].title)
          this.disabled = false
        }
      })
    },
    async syncAll () {
      for (let i = 0; i < this.entities.length; i++) {
        await this.sync(i)
      }
    },
    async fetchDealFields () {
      await this.$api.query('bitrix24/call/crm.deal.fields').then(response => {
        if (Object.keys(response.result).length) {
          const dealEntityIndex = this.entities.findIndex(el => el.code === 'deal')
          const bitrix24Options = []

          for (const code in response.result) {
            const item = response.result[code]

            bitrix24Options.push({
              value: code,
              text: item.listLabel ? item.listLabel : item.title
            })
          }

          Vue.set(this.entities[dealEntityIndex], 'bitrix24Options', bitrix24Options)
        }
      })
    },
    async fetchSettings () {
      await this.$api.query('option?module=bitrix24').then(response => {
        const options = response

        for (const optionName in options) {
          const entityCode = optionName.split('_')[1]
          const option = optionName.replace(`bitrix24_${entityCode}_`, '')
          const optionValue = options[optionName]
          const entityIndex = this.entities.findIndex(el => el.code === entityCode)

          this.entities[entityIndex].value.push({
            name: option,
            value: optionValue
          })
        }
      })
    },
    async saveOption (entityCode, name, value) {
      await this.$api.post('option/save', {
        [`bitrix24_${entityCode}_${name}`]: value
      })
    },
    async clearOption (entityCode, name, value) {
      const entityIndex = this.entities.findIndex(el => el.code === entityCode)
      const valueIndex = this.entities[entityIndex].value.findIndex(el => el.name === name)

      this.entities[entityIndex].value[valueIndex].value = ''
      await this.saveOption(entityCode, name, value)
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  }
}
</script>

<style scoped>
.bitrix24-entities {
  background-color: var(--grey-color);
  padding: 45px 50px;
}
.bitrix24-entities-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.bitrix24-entities-title h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--title-color);
}
.bitrix24-entity {
  padding: 20px;
  border-top: 1px solid white;
}
.bitrix24-entity-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--title-color);
  margin-right: auto;
}
.bitrix24-entity-inner {
  display: flex;
  align-items: center;
}
.bitrix24-entity-circle {
  margin-right: 20px;
}
.bitrix24-entity-message {
  margin-right: 50px;
}
.bitrix24-entity-title span {
  display: block;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
  margin-top: 5px;
}
</style>

<template>
  <div class="circle-percent" :style="{ borderColor: color }">
    <div v-if="percent >= 100" class="circle-percent-complete">
      <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5.39995L4.71429 8.99995L13 1" stroke="white" stroke-width="2"/>
      </svg>
    </div>
    <div class="circle-percent-half"><div class="circle-percent-pie" :style="{ backgroundColor: color, transform: 'rotate(' + deg1 + 'deg)' }"></div></div>
    <div class="circle-percent-half"><div class="circle-percent-pie" :style="{ backgroundColor: color, transform: 'rotate(' + deg2 + 'deg)' }"></div></div>
  </div>
</template>

<script>
export default {
  name: 'CirclePercent',
  props: {
    percent: Number
  },
  computed: {
    trimmedPercent () {
      return this.percent > 100 ? 100 : this.percent
    },
    deg1 () {
      return this.trimmedPercent < 50 ? this.trimmedPercent / 100 * 360 : 180
    },
    deg2 () {
      return this.trimmedPercent > 50 ? (this.trimmedPercent - 50) / 100 * 360 : 0
    },
    color () {
      return this.trimmedPercent < 80 ? 'var(--red-color)' : (this.trimmedPercent < 95 ? '#FACC5D' : '#66CB76')
    }
  }
}
</script>

<style>
.circle-percent {
  position: relative;
  width: 50px;
  height: 50px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 50%;
}
.circle-percent-half {
  position: absolute;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  clip: rect(0px, 38px, 38px, 19px);
}
.circle-percent .circle-percent-half:last-child {
  transform: rotate(180deg);
}
.circle-percent-pie {
  position: absolute;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  clip: rect(0px, 19px, 38px, 0px);
  background-color: black;
  opacity: .7;
  transition: transform .5s;
}
.circle-percent-complete {
  position: absolute;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
</style>

<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else class="integration-page">
    <div v-if="integration">
      <TopPanel
        back
        backText="Вернуться к списку интеграций"
        @back-click="$router.push({ name: 'integrations' })"
      />
      <div class="integration-info">
        <div class="integration-title">{{ integration.title }}</div>
        <span v-if="integration.connected" class="badge badge-success integration-item-connected">
          <span>Подключен</span>
          <svg><use :xlink:href="badgeSvg + '#ok'"></use></svg>
        </span>
        <div v-if="integration.desc" class="integration-desc">{{ integration.desc }}</div>
      </div>
      <router-view></router-view>
    </div>
    <div v-else class="integration-cat" v-for="(category, catIndex) in categories" :key="catIndex">
      <div class="integration-cat-title">{{ category.title }}</div>
      <div class="integration-cat-children">
        <router-link :to="!integrations[code].disabled ? '/integrations/' + code : ''" class="integration-item" v-for="code in category.children" :key="code">
          <div class="integration-item-image">
            <img :src="require('@/assets/img/integrations/' + code + '.png')" :alt="integrations[code].title">
          </div>
          <div class="integration-item-title">{{ integrations[code].title }}</div>
          <span class="badge badge-success integration-item-connected" v-if="integrations[code].connected">
            <span>Подключен</span>
            <svg><use :xlink:href="badgeSvg + '#ok'"></use></svg>
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import badgeSvg from '@/assets/svg/badge.svg'
import TopPanel from '@/components/TopPanel'

export default {
  name: 'Integrations',
  components: {
    TopPanel
  },
  data () {
    return {
      badgeSvg,
      isLoading: true,
      categories: {
        crm: {
          title: 'CRM системы',
          children: ['bitrix24', 'amocrm']
        },
        bank: {
          title: 'Банки',
          children: ['modulbank']
        }
      },
      integrations: {
        bitrix24: {
          title: 'Битрикс24',
          desc: '«Битрикс24» — российский сервис для управления бизнесом. Разработчик и провайдер — российская компания «1С-Битрикс»',
          connected: true
        },
        amocrm: {
          title: 'amoCRM',
          connected: false,
          disabled: true
        },
        modulbank: {
          title: 'Модульбанк',
          desc: 'Единственный банк в России, который работает только с малым бизнесом. Банковские сервисы для любого бизнеса. Защита от блокировок по 115–ФЗ.',
          connected: false
        }
      }
    }
  },
  computed: {
    integration () {
      return this.integrations[this.$route.meta.id]
    }
  },
  async created () {
    const result = await this.$api.query('integration')
    result.forEach(el => {
      this.integrations[el.id].connected = el.connected
    })

    this.isLoading = false
  }
}
</script>

<style scoped>
.integration-page {
  padding: 0 50px;
}
.integration-info {
  margin-bottom: 40px;
}
.integration-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  color: var(--title-color);
}
.integration-desc {
  margin-top: 15px;
  font-size: 15px;
  line-height: 25px;
  color: var(--title-color);
}
.integration-cat {
  margin-top: 60px;
}
.integration-cat-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--title-color);
  margin-bottom: 25px;
}
.integration-cat-children {
  display: flex;
  flex-wrap: wrap;
}
.integration-item {
  margin-right: 25px;
}
.integration-item:hover {
  text-decoration: none;
}
.integration-item-image {
  background: var(--grey-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  width: 381px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.integration-item-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--title-color);
  margin-top: 10px;
}
.integration-item-connected {
  position: relative;
  margin-top: 10px;
  color: var(--title-color);
}
.integration-item-connected span {
  display: block;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  padding: 3px 22px 8px 10px;
}
.integration-item-connected svg {
  width: 9px;
  height: 7px;
  position: absolute;
  top: calc(50% - 6px);
  right: 10px;
}
</style>
